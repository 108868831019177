import React from "react"
import {Col, Container, Row, Button} from "react-bootstrap"
import Layout from "../components/layout"
import CarouselStatic from "../components/carouselStatic"
import ParallaxGrid from "../../plugins/gatsby-theme-chegatsby/src/components/parallaxGrid"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import {FiMusic} from "react-icons/fi";
import {FaLock, FaPhone, FaPiggyBank, FaRegThumbsUp} from "react-icons/fa";
import {IoIosPeople} from "react-icons/io";
import {GiMusicalScore, GiSandsOfTime} from "react-icons/gi";
import {MdLibraryMusic} from "react-icons/md";
import YouTube from 'react-youtube';
import {Link, graphql, useStaticQuery} from "gatsby";
import CountUp, { startAnimation } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
const IndexPage = () => {
	
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "parallax"}}) {
                edges {
                    node {
                        childImageSharp {
                            original {
                                src
                            }
                        }
						base
                    }
                }
            }
        }
    `)
	
	const piano_image = data.allFile.edges.filter((elem)=> {return elem.node.base === "piano1.jpg"})[0]
	const mic_image = data.allFile.edges.filter((elem)=> {return elem.node.base === "mic2.jpg"})[0]

	
	return (
    <Layout pageInfo={{pageName: "index"}}>
        <SEO title="Home" keywords={[`Musica per cerimonie ed eventi`, `Ciro Triassi`, `Agenzia Musicale Salerno`, `Musica per Matrimonio`, `Animazione per Matrimonio`, `Agenzia Eventi e Spettacoli Napoli`, `Agenzia Eventi e Spettacoli Salerno`, `Ciro Triassi Music Live`]}/>
        <CarouselStatic/>

        <Container className="my-4 text-center">
            <FiMusic className="big-icon text-center my-4"/>
            <h5 className="mb-4">EVENTI E SPETTACOLO</h5>
            <h1 className="mb-4">CIRO TRIASSI MUSIC LIVE</h1>
            <Row className="my-4">
                <Col md={{span: 6, offset: 3}}>
                    <p>
                        Per tutti voi sposi che celebrerete il vostro matrimonio la Ciro Triassi Music Live propone
                        splendide performance musicali che sapranno animare con gioia e divertimento il vostro evento
                        più importante.
                    </p>
			<video class="videoPlayer__player" width="100%" preload="metadata" controlslist="nodownload" playsinline="" controls="controls">
				<source src="https://cdn1.matrimonio.com/emp/videos/6/5/6/IT_83656_1673950617_wa_video.mp4" type="video/mp4"/>
			</video>
                </Col>
            </Row>
            <Row className="my-4">
                <Col md={{span: 8, offset: 2}}>
                    <YouTube
					    containerClassName="embed-container" className="embed-container-iframe"
                        videoId="CeNMrct7ZC4"
                        opts={{
							height: null,
							width: null,
                            playerVars: { // https://developers.google.com/youtube/player_parameters
                                autoplay: 0,
                                rel: 0,
                                showinfo: 0,
                                modestbranding: 1,
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
        <ParallaxGrid bgImage={piano_image} bgClass="bg-fff-8">
		   <Row>
            <Col sm={{span: 12, offset: 0}} md={{span: 8, offset: 2}}>
             <h3>CHI SIAMO</h3>
             <p>Ciro Triassi è un professionista della musica da oltre 15 anni. Oggi opera in collaborazione
               con un team di validi musicisti professionisti. La famiglia artistica del suo staff è in
               grado di esibirsi con grande passione e professionalità, nonché eleganza e simpatia.</p>
             <p>
               <Link to="/chi-siamo" className="link-no-style">
                <Button variant="secondary" size="lg" className="round-button" active>Leggi tutto</Button>
               </Link>
             </p>
			</Col>
           </Row>
		</ParallaxGrid>
        <Container className="my-4 text-center">
            <h5 className="mt-4">4 SEMPLICI PASSI</h5>
            <h2 className="mb-4">COSA ASPETTI</h2>
            <Row>
                <Col className="text-center">
                    <Link to="/#contact" className="link-no-style"><FaPhone className="big-icon text-primary text-center my-4"/></Link>
                    <p>
                        Contattaci subito, chiariremo ogni tuo dubbio
                    </p>
                </Col>
                <Col className="text-center">
                    <Link to="/#contact" className="link-no-style"><FaLock className="big-icon text-primary text-center my-4"/></Link>
                    <p>
                        Non appena possibile blocca la tua data
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Link to="/promozioni" className="link-no-style"><FaPiggyBank className="big-icon text-primary  text-center my-4"/></Link>
                    <p>
                        Prenota prima e risparmia con le nostre promozioni
                    </p>
                </Col>
                <Col className="text-center">
                    <Link to="/video-portfolio" className="link-no-style"><MdLibraryMusic className="big-icon text-primary  text-center my-4"/></Link>
                    <p>
                        Concorda con noi i momenti musicali e i generi preferiti
                    </p>
                </Col>
            </Row>
        </Container>
		<ParallaxGrid bgImage={mic_image} bgClass="bg-000-8">
            <Row className="text-center text-light">
                <Col xs={6} sm={3}>
                    <GiSandsOfTime className="big-icon my-4"/>
                    <p>
                        <CountUp delay={0.5} end={124631} redraw={true}>
							{({ countUpRef, start }) => (
							<VisibilitySensor onChange={start} delayedCall>
								<><span style={{fontSize: '2em'}} className="font-weight-bold" ref={countUpRef} /><br/>Ore di esibizione</>
							</VisibilitySensor>
							)}
						</CountUp>
                    </p>
                </Col>
                <Col xs={6} sm={3}>
                    <GiMusicalScore className="big-icon my-4"/>
                    <p>
						<CountUp delay={0.5} end={7859} redraw={true}>
							{({ countUpRef, start }) => (
							<VisibilitySensor onChange={start} delayedCall>
								<><span style={{fontSize: '2em'}} className="font-weight-bold" ref={countUpRef} /><br/>Brani in repertorio</>
							</VisibilitySensor>
							)}
						</CountUp>
                    </p>
                </Col>
                <Col xs={6} sm={3}>
                    <FaRegThumbsUp className="big-icon my-4"/>
                    <p>
						<CountUp delay={0.5} end={7995} redraw={true}>
							{({ countUpRef, start }) => (
							<VisibilitySensor onChange={start} delayedCall>
								<><span style={{fontSize: '2em'}} className="font-weight-bold" ref={countUpRef} /><br/>Clienti soddisfatti</>
							</VisibilitySensor>
							)}
						</CountUp>
                    </p>
                </Col>
                <Col xs={6} sm={3}>
                    <IoIosPeople className="big-icon my-4"/>
                    <p>
						<CountUp delay={0.5} end={102} redraw={true}>
							{({ countUpRef, start }) => (
							<VisibilitySensor onChange={start} delayedCall>
								<><span style={{fontSize: '2em'}} className="font-weight-bold" ref={countUpRef} /><br/>Artisti a disposizione</>
							</VisibilitySensor>
							)}
						</CountUp>
                    </p>
                </Col>
            </Row>
		</ParallaxGrid>
    </Layout>
)
}

export default IndexPage
