import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import {Link} from 'gatsby';
import Img from 'gatsby-image';

const CarouselSlideImg = (props) => {

    const captionStyles = {
        padding: 20,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        height: "fit-content",
    };

    const bgmode = (props.slideMode === "light") ? "bg-fff" : "bg-000"
	const txtmode = (props.slideMode === "light") ? {color: "#333"} : {color: "#fff"}

    return (
        <>
            <div className={bgmode}><Img className="d-block w-100 slide-filter" fluid={{ ...props.slideImg, aspectRatio: props.ratio != undefined ? props.ratio : 1.6 }}/></div>
			{
				props.slideLink.startsWith("http") ? (
				<a href={props.slideLink} className="link-no-style">
					<Carousel.Caption style={{...txtmode, ...captionStyles}}>
						{props.children}
					</Carousel.Caption>
				</a>
				) : (
				<Link to={props.slideLink} className="link-no-style">
					<Carousel.Caption style={{...txtmode, ...captionStyles}}>
						{props.children}
					</Carousel.Caption>
				</Link>
				)
			}
        </>
    )
}

export default CarouselSlideImg
