import React from "react"
import {Container} from "react-bootstrap"
import {Parallax} from "react-parallax";

const ParallaxGrid = (props) => {


    return (
        <Parallax bgImage={props.bgImage.node.childImageSharp.original.src} strength={500}>
            <Container fluid className={props.bgClass}>
                <div style={{minHeight: props.minHeight}}className="parallax-container">{props.children}</div>
            </Container>
        </Parallax>
    )
}


export default ParallaxGrid
