import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import CarouselSlideImg from '../../plugins/gatsby-theme-chegatsby/src/components/carouselSlideImg'
import Button from 'react-bootstrap/Button'
//import CarouselSlideVideo from './carouselSlideVideo'
import {graphql, useStaticQuery} from 'gatsby';

const CarouselStatic = () => {


    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
                edges {
                    node {
                        childImageSharp {
                            fluid(quality: 90) {
                                ...GatsbyImageSharpFluid
                            }
                        }
						name
                    }
                }
            }
        }
    `)

    return (
        <Carousel className="full-width" interval={null}>
            <Carousel.Item>
                <CarouselSlideImg
                    slideMode="light"
					slideImg={data.allFile.edges.filter((file) => {return file.node.name === "slide-sposi"})[0].node.childImageSharp.fluid}
                    slideLink="/promo/speciale-sposi"
                >
                    <h3>PROMOZIONE</h3>
                    <h4 className="mb-4">SPECIALE SPOSI</h4>
                    <Button variant="primary" className="round-button" size="lg" active>leggi tutto</Button>
                </CarouselSlideImg>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselSlideImg
                    slideMode="dark"
                    slideImg={data.allFile.edges.filter((file) => {return file.node.name === "sax"})[0].node.childImageSharp.fluid}
                    slideLink="/promo/sax-bar-in-regalo"
                >
                    <h3>IN REGALO IL SAX BAR</h3>
                    <h4 className="mb-4">PRENOTA SUBITO</h4>
                    <Button variant="primary" className="round-button" size="lg" active>leggi tutto</Button>
                </CarouselSlideImg>
            </Carousel.Item>
            {/*<Carousel.Item>
	<CarouselSlideImg slideTitle="Slide 2" slideMode="dark" slideImg={data.allFile.edges[1].node.childImageSharp.fluid} slideLink="/promo" />
  </Carousel.Item>*/}
        </Carousel>
    )
}

export default CarouselStatic
